/*========== .pagenav-sect @ page-index ==========*/

$ek-pagenav-sect-height: 50px; // pagenav-sect の高さ.

.pagenav-sect {

	display: none;
	// z-index: 5;
	margin-bottom: 55px;
	position: relative;
	height: $ek-pagenav-sect-height;
	background-color: transparent;
	width: 100%;

	@include mq-up(md) { display: block; }

	.nav-container { width: 100%; }

	.sect-container {
		height: $ek-pagenav-sect-height; //height: 100%;
	}

	.pagenav-top { display: none; }


	// ul
	.pagenav-menu {
		list-style: none;
		text-align: center;
		font-size: 0;
		padding: 0;
	}

	.menu-item { display: inline-block; }

	.menu-item > a {
		display: inline-block;
		vertical-align: middle;
		font-size: 17px;
		font-weight: bold;
		color: $color-blue;
		line-height: $ek-pagenav-sect-height;
		text-decoration: none;
		padding: 0 0.5em;
		letter-spacing: -0.025em;
		@include font_fat_disable;
		@include transition;

		@include mq-up(lg) {
			padding: 0 0.95em;
		}
	}//.menu-item > a

	.menu-item.active > a {
		background-color: $color-blue;
		color: white;
	}
	.menu-item:hover > a {
		background-color: lighten($color-blue, 15%);
		color: white;
	}

}//.pagenav-sect



/*=== fixed ===*/
.pagenav-sect.fixed {

	z-index: 5;

	.nav-container {
		position: fixed;
		margin-bottom: 0;
		top: 0;
		left:0;
		background-color: white;
		@include box-shadow;
	}

	.sect-container {
		background: white url(../img/common/eiko-logo-blue.svg) left center no-repeat;
		padding-left: 110px;
		position: relative;
	}

	// 栄晃ロゴ
	.pagenav-top {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 112px;
		height: 100%;
		a {
			display: block;
			width: 100%;
			height: 100%;
			@include swaptext;
		}
	}

	.pagenav-menu {
		text-align: right;
	}
	.menu-item > a {
		font-size: 14px;
		@include mq-up(lg) { font-size: 17px; }
	}

}//.pagenav-sect.fixed
