@use 'sass:math';
// ====================
//
//  vars-mixins (variables & mixins)
//
// ====================

//=====
// 参考: Bootstrap 400b の variables (関連性の高いものだけ抜粋)
//
$grid-breakpoints: (
  xs: 0,
  ssm: 375px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
//
//
// // Grid containers
// //
// // Define the maximum width of `.container` for different screen sizes.
//
// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px
// ) !default;
// @include _assert-ascending($container-max-widths, "$container-max-widths");
//
//
// // Grid columns
// //
// // Set the number of columns and specify the width of the gutters.
//
// $grid-columns: 12 !default;
// $grid-gutter-width: 30px !default;
//
//=====


//====================
// variables
//====================

$screen-ssm-min: map-get($grid-breakpoints, ssm); // 375px
$screen-sm-min: map-get($grid-breakpoints, sm); // 576px
$screen-md-min: map-get($grid-breakpoints, md); // 768px tablets
$screen-lg-min: map-get($grid-breakpoints, lg); // 992px desktop
$screen-xl-min: map-get($grid-breakpoints, xl); // 1200px desktop-large

$font-family: "Meiryo", "メイリオ", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro", "Yu Gothic", "游ゴシック", "YuGothic", "游ゴシック体", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$font-family-mrou: "M PLUS Rounded 1c", "Meiryo", "メイリオ", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro", "Yu Gothic", "游ゴシック", "YuGothic", "游ゴシック体", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

$font-size: 16px;
$line-height: math.div( 24px, $font-size );

//$ek-site-header-height: 40px; // モバイル用固定ヘッダの高さ
$ek-site-header-height: 50px; // モバイル用固定ヘッダの高さ
$ek-site-header-height-md: 113px; // ヘッダの高さ:md以上


$color-black: #000;
$color-blue: #47c7ef; // メニューバー背景色など
$color-blue-lite: #91ddf5; // $color-blue の 60%.
$color-yellow: #ff0;
$color-orange: #ffcc33; // ボタン
$color-orange-dark: #ff7a00; // リンク

$ek-color-link: $color-orange-dark;

//====================
// mixins
//====================


// 指定px以上の幅 (min-width) のメディアクエリを記述するmixin
@mixin mq-up($breakpoint: md) {
	@media screen and (min-width: #{map-get($grid-breakpoints, $breakpoint)} ) {
		@content;
	}
}
// 使い方:
// @include mq-up(sm) {
//	... (ここの記述内容が @content としてそのまま使われる)
// }


@function getRem($px) {
	@return math.div($px, $font-size) * 1.0rem;
}
@function getEm($px, $base-px:$font-size) {
	@return math.div($px, $base-px) * 1.0em;
}

@mixin swaptext() {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}


@mixin border-radius($radius: 0.5em) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}


@mixin box-shadow($xdir:0px, $ydir:1px, $blur:2px, $color:rgba(0,0,0,0.4), $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $xdir $ydir $blur $color;
       -moz-box-shadow: inset $xdir $ydir $blur $color;
            box-shadow: inset $xdir $ydir $blur $color;
  } @else {
    -webkit-box-shadow: $xdir $ydir $blur $color;
       -moz-box-shadow: $xdir $ydir $blur $color;
            box-shadow: $xdir $ydir $blur $color;
  }
}

@mixin transition-solo($prop:all) {
	$duration:0.15s; $easing:linear; $delay:0.0s;
	-webkit-transition: $prop $duration $easing $delay;
	-moz-transition:    $prop $duration $easing $delay;
	-ms-transition:     $prop $duration $easing $delay;
	-o-transition:      $prop $duration $easing $delay;
	transition:         $prop $duration $easing $delay;
}

@mixin transition($prop:all, $duration:0.15s, $easing:linear, $delay:0.0s) {
	-webkit-transition: $prop $duration $easing $delay;
	-moz-transition:    $prop $duration $easing $delay;
	-ms-transition:     $prop $duration $easing $delay;
	-o-transition:      $prop $duration $easing $delay;
	transition:         $prop $duration $easing $delay;
}
// transition: property duration timing-function delay|initial|inherit;
// duration と delay は必ず単位 s をつけること.
// デフォは all 0s ease 0s
// 順番は必ず守ること. デフォでいい場合は後ろから省略できる.
// 例えばdelayはゼロでいいなら前の3つだけ指定してもいい (opacity 1s linear) とか.
//
// easing のキーワード: ease、linear、ease-in、ease-out、 ease-in-out



@mixin clearfix() {
  &:after {
    content: "";
    clear: both;
    height: 0;
    display: block;
    visibility: hidden;
  }
}


//=== (font-smoothing)の設定
@mixin font_fat_default(){
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  text-rendering: auto;// ただしまだstandardではない
}
@mixin font_fat_disable(){
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;// ただしまだstandardではない
}
// text-rendering は他にも値がある. 詳しくはMDN: https://developer.mozilla.org/en-US/docs/Web/CSS/text-rendering
