/*==================== 栄晃 (Nov.2017)
	
	.formset (fieldset.formset)
	
	注文フォーム, コンタクトフォームのフォームスタイル
	
	====================*/

$ek-form-table-border: 2px solid $color-blue-lite;

.req-mark { color: $color-orange-dark; }

.formset {
	
	border: none;
	padding: 0;
	margin: 30px auto;
	@include mq-up(sm) { margin: 45px auto; }
	
	.req-mark,
	.error-msg { color: $color-orange-dark; }
	
	.error-msg {
		font-size: 0.75em;
		line-height: 1.1;
		margin-bottom: 2px;
		&::before {
			content: "!";
			display: inline-block;
			width: 1.0em;
			height: 1.0em;
			background-color: $color-orange-dark;
			color: white;
			font-weight: bold;
			line-height: 1.0;
			text-align: center;
			font-family: "Times New Roman", "Times", serif;
			margin-right: 2px;
		}
		&.hidden { display: none; }
	}//.error-msg
	
	
}//.formset


/*=== テーブルの中 ===*/

.formset .form-table {
	
	border: $ek-form-table-border;
	border-collapse: collapse;
	width: 100%;
	max-width: 880px;
	margin: 0 auto;
	background-color: white;
	font-size: getEm(15px);
	
	th, td {
		display: block;
		padding: 10px;
		text-align: center;
		font-weight: normal;
		@include mq-up(sm) {
			padding: 20px;
			display: table-cell;
			text-align: left;
		}
	}//th, td
	
	.form-row + .form-row th { border-top: $ek-form-table-border; }
	.form-row + .form-row td { @include mq-up(sm) { border-top: $ek-form-table-border; } }
	
	th {
		background-color: rgba(0,0,0,0.08);
		@include mq-up(sm) {
			background-color: transparent;
			width: 27.5%;
		}
	}

	input[type="text"],
	input[type="email"],
	input[type="tel"],
	textarea {
		width: 100%;
		border: 1px solid #c3c3c3;
		padding: 0.5em;
	}
	
	input[type="radio"] { margin-right: 0.25em; }
	
	textarea { line-height: 1.35; }
	
	select {
		border: 1px solid #c3c3c3;
		height: 2.0em;
		padding: 0 1.0em;
	}
	
	/* ラジオボタンのUL */
	.radio-list {
		
		list-style: none;
		padding: 0;
		
		li { margin: 0.25em auto; }
		
		@include mq-up(sm) {
			li { display: inline-block; }
			li + li { margin-left: 2.0em; }
		}
		
	}//.radio-list
	
	
	p.note {
		margin: 0.75em 0 0 0;
		font-size: getEm(15px);
	}
	
}//.formset .form-table

.formset-title,
.formset-note {
	text-align: center;
}

.formset-title {
	display: block;
	font-weight: bold;
	margin: 0 auto 0.5em; // <== legend のテキストを中央揃えに見せるには, ボックスを中央配置する必要がある.
	&.sr-only { display: none; }
}

.formset-note { margin-bottom: 1.0em; }


/*=== ボタンなどのボックス ===*/

.formset.controllers {
	
	text-align: center;
	margin: 60px auto;
	@include mq-up(sm) { margin: 75px auto; }
	
	.submit-btn-container {
		margin: 20px auto 0;
		width: 290px;
		overflow: hidden;
		position: relative;
		@include mq-up(sm) { width: 450px; }
	}
	
	.btn-orange {
		border: none;
		float: left;
		width: 140px;
		height: 40px;
		@include border-radius(5px);
		
		&+.btn-orange { margin-left: 10px; }
		
		@include mq-up(sm) { width: 220px; }
		@include mq-up(md) { cursor: pointer; }
	}
	
	.disabled_catcher {
		display: none;
		position: absolute;
		width: 140px;
		height: 40px;
		background-color: rgba(0,0,0,0.1);
		@include border-radius(5px);
		left: 0;
		top: 0;
		@include mq-up(sm) { width: 220px; }
		&.active { display: block; }
	}
	
}//.formset.controllers