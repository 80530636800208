/*========== .howto-sect @ page-index ==========*/



.howto-sect {

	margin-bottom: 50px;
	@include mq-up(sm) { margin-bottom: 80px; }

	.howto-list {
		padding: 0;
		list-style: none;
		margin: 0 auto 20px;
		max-width: 816px;
		@include mq-up(md) { margin-bottom: 40px; }
	}

	.howto-item-container {
		background-color: white;
		border: 2px solid $color-blue-lite;
		padding: 15px 10px 10px;
		@include mq-up(md) { padding: 15px 20px 20px; }
	}

	//arrow
	.howto-item + .howto-item::before {
		display: block;
		content: "";
		width: 100%;
		height: 35px;
		background: url(../img/index/idx-howto-arrow.svg) center center / 25px auto no-repeat;
		@include mq-up(md) { height: 55px; }
	}

	.howto-item-title {
		color: $color-blue-lite;
		font-size: getEm(23px);
		font-weight: normal;
		text-align: center;
		margin-bottom: calc(6 / 23 * 1.0em);
		@include mq-up(md) { font-size: getEm(28px); }
	}

	.howto-note-list {
		list-style: none;
		padding-left: 1.0em;
		text-indent: -1.0em;
		font-size: getEm(15px);
		& > li { margin-bottom: 0.5em; }
		@include mq-up(md) { font-size: getEm(17px); }
	}

	.time-period-list {
		padding: 0;
		list-style: none;
		overflow: hidden;
		text-indent: 0;
		& > li {
			display: block;
			float: left;
			margin-left: 1.0em;
			@include mq-up(md) { margin-left: 1.5em; }
			&::before { content: "• "; }
		}
	}

	.btn-orange {
		@include mq-up(md) { width: 300px; margin-top: 10px; }
	}


	/*=== notes-box ===*/

	.notes-box {
		max-width: 816px;
		margin: 0 auto;
	}

	.notes-box-title {
		font-weight: normal;
		font-size: getEm(20px);
		margin-bottom: 15px;
		@include mq-up(md) {
			font-size: getEm(24px);
			margin-bottom: 30px;
		}
	}

	.note-list {
		padding-left: 1.0em;
		text-indent: -1.0em;
		list-style: none;
		font-size: getEm(12px);
		& > li { margin-bottom: 0.5em; }
		@include mq-up(sm) { font-size: getEm(14px); }
	}

}//.areas-sect
