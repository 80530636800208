/*==================== 栄晃 (Nov.2017)
	
	FAQ
	
	====================*/

.page-faq {
	
	
	
	/*=== .intro-sect タイトル部 ===*/
	.intro-sect {
		background-image: url(../img/faq/faq-title-bg.jpg);
	}
	
	
	
	.faq-sect {
		
		.sect-lead { margin-bottom: 50px; }
		
		.grp-title {
			font-size: 1.0em;
			text-align: center;
			margin-bottom: 1.0em;
		}
		
		.faq-grp {
			margin-bottom: 60px;
		}
		
	}//.faq-sect
	
	
}//.page-faq


.single-faq {
	
	overflow: hidden;
	
	& + .single-faq { margin-top: 10px; }
	
	/* 質問部 */
	.sfaq-header {
		display: table;
		width: 100%;
		background-color: #e9e9e9;
		background: #e9e9e9 url(../img/index/idx-info-open-icon.svg) (right 15px) center / 38px auto no-repeat;
		overflow: hidden;
		
		&.opened {
			background-image: url(../img/index/idx-info-close-icon.svg);
		}
		
		@include mq-up(md) {
			cursor: pointer;
			&:hover { background-color: darken(#e9e9e9, 7.5%); }
		}
	}//.sfaq-header
	
	.sfaq-num {
		display: table-cell;
		background-color: #c3c3c3;
		vertical-align: middle;
		text-align: center;
		width: 50px;
		@include mq-up(sm) {
			width: 60px;
		}
	}
	.sfaq-title {
		font-weight: normal;
		padding: 12px 60px 12px 12px;
		display: table-cell;
		line-height: 1.40;
		@include mq-up(sm) {
			line-height: 1.5;
			padding: 20px 70px 20px 15px;
		}
	}
	
	
	/* 回答部 */
	.sfaq-body {
		line-height: 1.40;
		height: 0;
		padding: 0;
		@include transition-solo(padding);
		
		&.visible {
			height: auto;
			padding: 15px 0;
		}
		
		@include mq-up(sm) {
			line-height: 1.50;
			padding: 0 20px 0 75px;
			&.visible { padding: 20px 20px 20px 75px; }
		}
		
	}//.sfaq-body
	
}//.single-faq