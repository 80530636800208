/*========== .areas-sect @ page-index ==========*/



.areas-sect {
	
	margin-bottom: 50px;
	padding-top: 180px;
	background: url(../img/index/idx-areas.png) center top / 300px auto no-repeat;

	.area-list {
		font-size: getEm(20px);
		margin-bottom: 20px;
	}
	
	.note-list {
		list-style: none;
		padding-left: 1.0em;
		text-indent: -1.0em;
		font-size: getEm(14px);
	}

	@include mq-up(sm) {
		
		margin-bottom: 0;
		padding-bottom: 80px;
		padding-top: 0;
		background-size: 55% auto;
		background-position: -12% 95px;
		
		.sect-title { margin-bottom: 50px; }
		
		.area-list,
		.note-list {
			margin-left: 50%;
		}
		
	}//-sm

	@include mq-up(md) {
		padding-bottom: 160px;
		background-size: 52% auto;
		background-position: -12% 150px;
		.sect-title { margin-bottom: 100px; }
	}//-md

	@include mq-up(lg) {
		background-position: -12% 130px;
	}//-lg

}//.areas-sect