/*==================== 栄晃
	
	wp-post スタイル
	
	WordPressの投稿内容での基本スタイル (主に揃えクラス)
	
	====================*/



article.post {
	img {
		max-width: 100%;
		margin: 0;
		height: auto;
		width: auto;
	}

	.alignleft,
	img.alignleft {
		float: left;
		margin: 0.75em 0.75em 0.75em 0;
	}
	
	.alignright,
	img.alignright {
		float: right;
		margin: 0.75em 0 0.75em 0.75em;
	}
	
	.aligncenter,
	img.aligncenter {
		clear: both;
		display: block;
		margin: 1.5em auto;
	}
	
}//arcitle.post