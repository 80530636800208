/*==================== 栄晃

	header.site-header

	====================*/



.site-header {

	height: $ek-site-header-height;
	width: 100%;
	background-color: $color-blue;
	position: fixed;
	z-index: 10;
	padding: 5px 0;
	left: 0;
	top: 0;
	@include box-shadow(0, 1px, 2px, rgba(0,0,0,0.5), false);

	@include mq-up(md) {
		z-index: 0;
		height: $ek-site-header-height-md;
		position: relative;
		@include box-shadow(0, 0, 0, transparent, false);
		padding: 0;
	}

	.sect-container {
		overflow: hidden;
		padding: 0 8px 0 11px;
		height: 100%;
		//width: 100%;
		//@include mq-up(sm) { width: 100%; }
		@include mq-up(md) { padding: 0 15px; }
	}


	/*===== 左側 (社名ロゴ) =====*/

	$ek-container-left-width: 126px;
	$ek-container-left-width-md: 200px;

	.container-left {
		float: left;
		width: $ek-container-left-width;
		height: 100%;
		//margin-left: -4px;
		@include mq-up(md) {
			//margin-left: 0;
			width: $ek-container-left-width-md;
		}
	}

	/* 社名ロゴ */
	.site-name {
		display: block;
		margin: 0;
		width: 100%;
		height: 100%;
		//background: url(../img/common/eiko-logo-white.svg) 0px 4px / 80.5% auto no-repeat;
		background: url(../img/common/eiko-logo-white.svg) 0px 4px / auto 82% no-repeat;
		@include swaptext;

		@include mq-up(md) {
			//background-size: 80.5% auto;
			background-size: contain;
			//background-position: 40px 30px;
			background-position: 0px 30px;
		}
	}//.site-name



	/*===== 右側 =====*/

	.container-right {
		float: right;
		//margin-right: -7px;
		width: calc( 100% - #{ $ek-container-left-width } );
		overflow: hidden;

		//background-color: rgba(0,0,0,0.1);

		@include mq-up(md) {
			//margin-right: 0;
			width: calc( 100% - #{$ek-container-left-width-md} );
		}
	}//.container-right

	/* 右側: ページタイトル + 業務時間と電話番号 */
	.container-titles {
		float: left;
		width: calc(100% - 40px);
		//height: 100%;
		text-align: right;
		padding-right: 8px;

		//background-color: rgba(0,0,0,0.1);

		@include mq-up(sm) {
			overflow: hidden;
		}

		@include mq-up(md) {
			float: none;
			width: 100%;
			overflow: hidden;
			padding-right: 0;
		}
	}//.container-titles

	/* 営業時間+電話番号 */
	.hourntel-container {
		color: white;
		padding-top: 4px;

		//background-color: rgba(0,0,0,0.1);

		@include mq-up(sm) {
			float: right;
		}
		@include mq-up(md) {
			//float: right;
			//padding-right: 40px;
			padding-top: 34px;
			//margin-left: 10px;
		}
		@include mq-up(lg) { padding-top: 25px; }

	}//.hourntel-container

	/* 営業時間 */
	.bushour {
		display: none;
		font-size: getEm(11px);
		margin: 0;
		@include mq-up(sm) { display: block; }
		@include mq-up(md) { font-size: getEm(14px); }
		@include mq-up(lg) { font-size: getEm(17px); }
	}

	/* 電話番号 */
	.bustel {
		margin: 0;
		font-size: getEm(13px);
		line-height: calc(20 / 13 * 1.0em);

		a { color: white; text-decoration: none; }

		a::before {
			content: "";
			display: inline-block;
			width: (18px + 2);
			height: 14px;
			background: url(../img/common/tel-icon-white.svg) left top / auto 100% no-repeat;
		}//a::before

		/* 非モバイルではリンクをオフるので, リンクぽいスタイルは排除. */

		@include mq-up(md) {
			font-size: getEm(24px);
			a { cursor: default; }
			a::before {
				position: relative;
				top: 1px;
				width: 36px;
				height: 24px;
			}
		}//-md

		@include mq-up(lg) {
			font-size: getEm(30px);
			a::before {
				top: 2px;
				width: 44px;
				height: 30px;
			}
		}//lg

	}//.bustel


	/* サイトタイトル */
	// .site-title-container {}

	.site-title {

		color: $color-yellow;
		font-size: getEm(11px);
		font-weight: bold;
		margin: 0;
		line-height: 1.0;

		small {
			display: none;
			font-size: 0.5em;
			font-weight: normal;
		}

		@include mq-up(sm) {
			text-align: center;
			padding-top: 5px;
			font-size: getEm(19px);
			small {
				display: block;
				margin-bottom: 4px;
			}
		}

		@include mq-up(md) {
			//text-align: center;
			padding-top: 40px;
			font-size: getEm(24px);
			small {
				//display: block;
				margin-bottom: 8px;
			}
		}//-md
		@include mq-up(lg) {
			padding-top: 34px;
			font-size: getEm(30px);
		}//-lg

	}//.page-title


	/* 右側: メニューボタン */
	.menubtn-container {
		width: 40px;
		float: right;
		padding-top: 5px;

		@include mq-up(md) { display: none; }
	}

	.header_menu_btn {
		width: 40px;
		height: 30px;
		background: darken( $color-blue, 15% ) url(../img/common/xs-menu-icon.svg) center center no-repeat;
		border: 1px solid rgba(255,255,255,0.5);
		@include border-radius(4px);
		@include swaptext;
		text-indent: 150%;
	}

}//.site-header



/*=== header_menu ===*/

.header_menu_cover,
.header_menu {
	position: fixed;
	left: 100vw;
	@include transition(left, 0.15s, ease-out, 0.0s);
	&.active { left: 0; }
}

.header_menu_cover {
	z-index: 20;
	top: $ek-site-header-height;
	width: 100%;
	height: calc(100vh - 40px);
	background-color: rgba(0,0,0, 0.75);
}

.header_menu {
	z-index: 30;
	top: $ek-site-header-height + 2px;
	width: 100%;
	background-color: $color-blue;
	@include box-shadow(0, 1px, 2px, rgba(0,0,0,0.5), false);
}

.header-menu-block {
	margin: 0 auto;
	padding: 0.5em;
	width: 100%;
	overflow: hidden;
	list-style: none;

	.menu-item {
		width: 50%;
		float: left;
		padding: 0.5em;
	}
	.menu-item > a {
		line-height: calc(18 / 16);
		color: white;
		text-decoration: none;
	}
	.menu-item.active { background-color: darken( $color-blue, 18% ); }

	/* プライバシーポリシー */
	//&.three .menu-item:nth-child(3) { width: 100%; }

	/* facebook */
	&.three .menu-item:nth-child(4) > a::before {
		display: inline-block;
		width: 15px;
		height: 15px;
		content: "";
		background: #3c4e8a url(../img/common/fb-icon-white.svg) center center / auto 66.667% no-repeat;
		margin-right: 4px;
	}
}

.header-menu-block + .header-menu-block {
	border-top: 1px solid white;
}
