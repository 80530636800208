/*==================== 栄晃 (Nov.2017)

	contact ディレクトリ配下

	連絡フォーム

	====================*/

.page-contact {

	/* タイトル部 */
	.intro-sect {
		background-image: url(../img/contact/contact-title-bg.jpg);
	}


	/*========== index.html ==========*/


	.form-intro {

		.intro-body {

			max-width: 880px;
			margin: 0 auto;
			line-height: calc(28 / 16);

			.body-txt { margin-bottom: 0.5em; }

			.body-note-list {
				border: 1px solid #909090;
				border-left: none;  border-right: none;
				padding: 1.0em 0;
				margin: 1.0em 0;
				list-style: none;
				font-size: getEm(15px);
				@include mq-up(sm) { font-size: 1.0em; }

				.note-item {
					padding-left: 1.0em; text-indent: -1.0em; margin-bottom: 0.25em;
				}//.note-item

			}//.body-note-list

		}//.intro-body

	}//.form-intro



	/*========== contact-sent.html ==========*/

	.sent-sect {
		display: none;
		text-align: center;
		margin: 25px auto;

		&.visible { display: block; }

		p { margin-bottom: 0.5em; }
	}//.sent-sect

	.btn-container {
		margin: 25px auto;
		padding: 0 15px;
	}

}//.page-contact
