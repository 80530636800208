/*========== .intro-sect @ page-index ==========*/

.intro-sect {
	
	padding-top: 15px;
	margin-bottom: 75px;
	
	@include mq-up(md) {
		padding-top: 0;
		margin-bottom: 50px;
	}
	@include mq-up(lg) {
		margin-bottom: 15px;
	}
	
	.sect-container {
		background: url(../img/index/idx-intro.png) center 80px / 260px auto no-repeat;
		
		//background-color: rgba(0,0,0,0.1);
		
		@include mq-up(sm) {
			width: $screen-sm-min;
			padding: 0;
			margin-left: auto;
			margin-right: auto;
			background-position: right center;
			min-height: 200px;
		}
		@include mq-up(md) {
			width: $screen-md-min;
			min-height: 280px;
			background-size: auto 280px;
			background-position: (right 10px) center;
		}
		@include mq-up(lg) {
			width: $screen-lg-min;
			min-height: 360px;
			background-size: auto;
		}
	
	}//.sect-container

	/* xsのためのタイトル */
	.intro-title {
		color: #ff0;
		font-size: getEm(26px);
		text-align: center;
		line-height: 1.2;
		margin-bottom: 235px;
		
		small {
			font-size: 0.5em;
			font-weight: normal;
		}
		@include mq-up(sm) { display: none; }
	}
	
	/* 本文ブロック */
	.intro-body {
		
		text-align: center;
		font-weight: bold;
		color: white;
		font-size: 1.0em;
		-webkit-font-smoothing: antialiased;
		
		@include mq-up(sm) {
			width: 55%;
			padding-top: 1vw;
		}
		
		@include mq-up(md) {
			width: 50%;
			font-size: getEm(18px);
			padding-top: 35px
		}
		@include mq-up(md) {
			padding-top: 60px
		}		
	}//.intro-body

	.lead-text {
		margin-bottom: 0.5em;
		strong { font-size: 1.3333em; }
	}
	
	.featured-text {
		color: #ff0;
		font-size: 1.2222em;
		line-height: 1.40;
		strong { font-size: 1.72em; }
		@include mq-up(md) { strong { font-size: 1.9318em; } }
	}
	
	.note-text { font-size: 1.2222em; }
	
}//.intro-sect
