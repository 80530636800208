/*========== .contact-sect @ page-index ==========*/



.contact-sect {
	
	margin-bottom: 50px;
	@include mq-up(sm) {
		margin-bottom: 65px;//80px;
	}
	
	.contact-box {
		background-color: white;
		border: 2px solid $color-blue-lite;
		text-align: center;
		padding: 20px 10px;
		max-width: 740px;
		margin: 0 auto;
		@include mq-up(md) { padding: 20px 20px 25px; }
	}
	
	.contact-box-body {
		
		.bhour { margin-bottom: 15px; }
		
		.bphone {
			font-size: getEm(24px);
			margin-bottom: 15px;
			a {
				text-decoration: none;
				color: inherit;
			}
		}//.bphone
		
		.bphone > .label {
			display: inline-block;
			vertical-align: top;
			width: 40px;
			height: 26px;
			background: url(../img/common/tel-icon-black.svg) left bottom / contain no-repeat;
			@include swaptext;
		}
		
		@include mq-up(md) {
			.bhour, .bphone { display: inline-block; margin-bottom: 0; }
			.bhour { font-size: getEm(17px); }
			.bphone {
				margin-left: 20px;
				font-size: getEm(30px);
				a { cursor: default; }
			}
			.bphone > .label {
				width: 44px;
				height: 30px;
				position: relative;
				top: 4px;
			}
		}//-md
		
	}//.contact-box-body
	
	
	.btn-orange {
		display: block;
		max-width: 300px;
		margin: 0 auto;
		@include mq-up(sm) {
			display: inline-block;
			width: 46%;
			margin: 0 1%;
		}
	}//.btn-orange
	
	.btn-orange + .btn-orange { margin-top: 20px; }
	
}//.contact-sect