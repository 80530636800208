/*==================== 栄晃
	
	index.html
	
	====================*/

.page-index {
	
	@import "index-parts/index-intro";
	
	@import "index-parts/index-slidenav";
	
	@import "index-parts/index-news";
	
	@import "index-parts/index-services";
	
	@import "index-parts/index-options";
	
	@import "index-parts/index-areas";
	
	@import "index-parts/index-howto";
	
	@import "index-parts/index-contact";

	@import "index-parts/index-links";
	
}
/* /.page-index */
