/*========== .options-sect @ page-index ==========*/



.options-sect {

	margin-bottom: 50px;
	@include mq-up(sm) { margin-bottom: 80px; }

	.opt-row {
		margin-bottom: 30px;
		font-size: 0;
	}

	.opt-col {
		margin-top: 30px;
		font-size: 1.0rem;

		@include mq-up(sm) {
			display: inline-block;
			vertical-align: top;
			width: 47%;
			margin: 30px 1.5% 0;
			//margin-bottom: 30px;
		}//-sm

		@include mq-up(lg) {
			width: 31%;
			margin: 30px 1.1666% 0;
		}

	}//.opt-col



	/*=== .opt-colの中身 ===*/

	.opt-col-tn {
		margin-bottom: 15px;
	}

	.opt-col-tn > img {
		display: block;
		width: 100%;
		height: auto;
	}

	.opt-col-title {
		font-size: getEm(14px);
		text-align: center;
		line-height: calc(24 / 14);
		margin-bottom: 0.5em;
	}

	.opt-col-price {
		font-size: getEm(16px);
		text-align: center;
		margin-bottom: 15px;
	}

	.opt-col-text {
		font-size: getEm(12px);
		line-height: calc(21 / 12);
	}



	/*=== .opt-col.extra ===*/

	.opt-col.extra {


		.opt-col-title {
			font-size: getEm(20px);
			font-weight: normal;
		}

		.option-list {
			padding-left: 1.0em;
			font-weight: bold;
		}

		@include mq-up(md) {
			padding: 1.0em;
			.opt-col-title { text-align: left; }
		}//-md
		@include mq-up(lg) {
			width: 62%;
			.extra-container {
				max-width: 320px;
				margin: 0 auto;
			}
		}//-lg

	}//.opt-col.extra



	/*=== .note-list ===*/

	.note-list {
		padding-left: 1.0em;
		text-indent: -1.0em;
		list-style: none;
		& > li + li { margin-top: 10px; }
	}

}//.options-sect
