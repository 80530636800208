/*========== .slidenav-sect @ page-index ==========*/

//.slidenav-sect.menu-fixed {
//	@include mq-up(md) {
//		padding-top: 105px;
//	}
//}

.slidenav-sect {
	
	margin-bottom: 50px;
	@include mq-up(sm) { margin-bottom: 80px; }
	
	.sect-container {
		overflow: hidden;
		height: 100%;
		padding: 0 5px;
	}
	
	/* slide-list (ul) のコンテナ */
	.slide-container {
		position: relative;
		overflow: hidden;
		height: 100%;
		width: calc(100% - 58px);
		float: left;
	}
	
	// ul
	.slide-list {
		/* width は JavaScriptにより設定するので, ここでは設定しない */
		position: relative;
		height: 100px;
		margin: 0 auto;
		padding: 0;
		list-style: none;
		overflow: hidden;
	}
	
	// li
	.slide-item {
		width: 280px;
		height: 100px;
		float: left;
		margin: 0 10px;
		overflow: hidden;
		background-color: $color-blue;
		border: 2px solid $color-blue;
		@include transition-solo(border-color);
	}
	.slide-item > a {
		display: block;
		width: 100%;
		height: 100%;
	}
	.slide-item > a > img {
		display: block;
		width: 100%;  height: auto;
		opacity: 1.0;
		@include transition-solo(opacity);
	}
	
	@include mq-up(md) {
		.slide-item:hover { border-color: darken($color-blue, 20%); }
		.slide-item:hover img { opacity: 0.80; }
	}
	
	
	// btn
	
	.slidenav-btn {
		
		float: left;
		//width: 34px;
		width: 29px;
		height: 100px;
		padding: 0;
		border: none;
		background-color: transparent;
		background-repeat: no-repeat;
		cursor: pointer;
		opacity: 1.0;
		@include transition-solo(opacity);
		
		
		&.prev {
			//width: 24px;
			background-image: url(../img/index/idx-slide-icon-prev.svg);
			background-position: left center;
		}
		&.next {
			background-image: url(../img/index/idx-slide-icon-next.svg);
			background-position: right center;
		}
		@include mq-up(md) { &:hover { opacity: 0.70; } }
		
		
	}//.slidenav-btn
	
	
	
}//.slidenav-sect