/*==================== 栄晃

	news/index.html (WordPress)

	お知らせ一覧ページ

	====================*/

.page-news {

	/* タイトル部 */
	.intro-sect {
		background-image: url(../img/news/news-title-bg.jpg);
	}

	// .news-list-sect {}

	.news-list {
		max-width: 640px;
		margin: 0 auto 60px;
		text-align: center;
		list-style: none;
		padding: 0;
	}

	.news-list > li {
		margin-bottom: 1.0em;
	}


	/*=== .pagination-wp ===*/

	.pagination-wp {

		margin-bottom: 50px;

		@include mq-up(md) {
			margin-bottom: 100px;
		}

		.page-numbers {
			list-style: none;
			padding: 0;
			text-align: center;
		}

		.page-numbers > li {
			display: inline-block;
			width: 40px; height: 40px;
			line-height: 40px;
			background-color: darken($color-orange, 20%);
			color: white;
		}

		.page-numbers > li > a {
			display: block;
			width: 100%;  height: 100%;
			color: black;
			background-color: $color-orange;
			line-height: 40px;
			text-decoration: none;
			&:hover {
				background-color: darken($color-orange, 20%);
				color: white;
			}//hover
		}//.page-numbers > li > a

		//.page-numbers > li > .current {}


	}//.pagination-wp



	/*=============== for posts ===============*/

	.article-sect {

		article.post {
			max-width: 850px;
			margin: 0 auto 30px;
		}

		.post-date,
		.post-title {
			text-align: center;
			margin-bottom: 25px;
		}

		.post-title { font-size: getEm(16px); }

		.post-body {
			font-size: getEm(15px);
			line-height: calc(28 / 16);
			@include mq-up(md) { font-size: 1.0em; }
		}

	}//.article-sect


	/* .pagination-wp.post - ページネーション (投稿用) */
	.pagination-wp.post {

		// .sect-container {
		// 	border: 1px solid black;
		// 	border-left: none;  border-right: none;
		// 	padding-top: 1.4em;
		// 	padding-bottom: 1.4em;
		// }

		.page-numbers {
			border: 1px solid black;
			border-left: none;  border-right: none;
			padding-top: 1.4em;
			padding-bottom: 1.4em;
		}

		.page-numbers > li {
			width: auto; height: auto;
			background-color: transparent;
			& + li { border-left: 1px solid black; }
		}//.page-numbers > li

		.page-numbers > li > a {

			line-height: 1.0;
			color: $ek-color-link;
			background-color: transparent;
			padding: 0 0.5em;
			font-size: getEm(15px);

			@include mq-up(md) {
				font-size: 1.0em;
				padding: 0 1em;
				&:hover {
					color: darken($ek-color-link, 20%);
					text-decoration: underline;
				}//hover
			}//-md

		}//.page-numbers > li > a

	}//.pagination-wp.post


}//.page-news
