/*==================== 栄晃
	
	footer.site-footer
	
	====================*/



.site-footer {
	
	padding: 45px 0 30px;
	@include mq-up(md) { padding: 60px 0 45px; }
	
	.footer-nav {
		display: none;
		margin-bottom: 40px;
		@include mq-up(md) { display: block; }
	}
	
	.nav-list {
		padding: 0;
		list-style: none;
		font-size: 0;
		text-align: center;
	}
	.nav-item {
		display: inline-block;
		font-size: 1.0rem;
		padding: 0 20px;
		line-height: 1.0;
		//a { color: #ff7a00; text-decoration: none; }
		//a:hover { color: darken(#ff7a00, 10%); text-decoration: underline; }
	}
	.nav-item + .nav-item { border-left: 1px solid black; }
	
	.colophon {
		font-size: getEm(15px);
		text-align: center;
		line-height: 1.0;
	}
	
}//.site-footer
