/* ====================

	base

	基礎、共通

==================== */



/* --------------------
	basic-tags
-------------------- */

html { box-sizing: border-box; }
*, *:before, *:after { box-sizing: inherit; }

p,ul,ol,h1,h2,h3,h4,h5,h6,figure { margin: 0; }

html, body {
	font-family: $font-family;
	font-size: $font-size;
	line-height: $line-height;
	color: $color-black;
}

body {
	position: relative;

	background-color: white;
	background-image: url(../img/common/page-bg-top.svg), url(../img/common/page-bg-tile.svg);
	background-repeat: no-repeat, repeat-y;
	background-position: center top, center 859px;
	background-size: auto 280px, auto;

	@include mq-up(md) { background-size: auto, auto; }

	&.page-index {
		background-size: auto 800px, auto;
		@include mq-up(sm) { background-size: auto, auto; }
	}
}

a {
	color: $ek-color-link;
	text-decoration: none;
	@include transition-solo(color);
}
a:hover {
	color: darken($ek-color-link, 10%);
	text-decoration: underline;
}
a.ek-phone-link {
	@include mq-up(md) {
		color: inherit;
		cursor: default;
		&:hover { text-decoration: none; }
	}
}



/* --------------------
	basic-classes
-------------------- */

.page-container {
	width: 100%;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
}

/* 全ページ共通のメインコンテンツ用コンテナ */
.page-main {
	padding-top: $ek-site-header-height;
	@include mq-up(md) { padding-top: 0; }
}

/* スクリーンリーダー専用要素 */
.sr-only { display: none; }

.xs-only { @include mq-up(sm) { display: none; } }

.text-underline { text-decoration: underline; } // 下線

/* 各セクションの内部用コンテナ. 主にコンテンツの最大幅を決めている. */
.sect-container {
	padding: 0 15px;
	max-width: $screen-lg-min;
	margin: 0 auto;
	@include mq-up(sm) {
		//padding: 0;
		width: $screen-sm-min;
	}
	@include mq-up(md) { width: $screen-md-min; }
	@include mq-up(lg) { width: $screen-lg-min; }
}

/* 各セクションのタイトルスタイル */
.sect-title {
	font-size: getEm(28.5px);
	font-weight: normal;
	text-align: center;
	margin: 0 auto 0.85em;
	border-bottom: 1px solid black;
	max-width: 410px;
	@include mq-up(sm) { margin-bottom: 30px; }
	@include mq-up(md) {
		font-size: getEm(35px);
	}
}//.sect-title
/* 各セクションのタイトル: オレンジの一見リード版 */
.sect-title.orange {
	font-size: getEm(23px);
	line-height: calc(40 / 25);
	font-weight: bold;
	max-width: none;
	border-bottom: none;
	color: $color-orange-dark;
	text-align: center;
	margin-bottom: 0.25em;
	@include mq-up(sm) {
		font-size: getEm(25px);
	}
}//.sect-title.orange


.btn-container { text-align: center; }

.btn-orange {
	display: inline-block;
	background-color: $color-orange;
	color: black;
	text-decoration: none;
	line-height: calc(40 / 16);
	padding: 0 1.0em;
	width: 100%;
	@include transition;

	@include mq-up(md) {
		width: auto;
		&:hover {
			color: white;
			background-color: darken( $color-orange, 20% );
			text-decoration: none;
		}
	}//-md+

}//.btn-orange

