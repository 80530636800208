/*========== .services-sect @ page-index ==========*/



.services-sect {
	
	margin-bottom: 50px;
	@include mq-up(sm) { margin-bottom: 80px; }
	
	.service-box {
		background-color: white;
		border: 2px solid #91ddf5;
		max-width: 880px;
		margin: 0 auto;
	}
	.service-box + .service-box { margin-top: 20px; }
	
	.sbox-header { cursor: pointer; }
	
	.sbox-title {
		font-size: getEm(24px);
		line-height: 1.1;
		text-align: center;
		font-weight: normal;
		color: $color-blue;
		padding: 20px 0 17px;
		
		@include mq-up(md) {
			font-size: getEm(30px);
			padding: 30px 0 27px;
		}
	}//.sbox-title
	.sbox-title::after {
		content: "";
		display: inline-block;
		width: 18px;
		height: 14px;
		background: url(../img/index/idx-info-open-icon.svg) center center / contain no-repeat;
		margin-left: 4px;
	}
	.sbox-header.opened .sbox-title::after {
		background-image: url(../img/index/idx-info-close-icon.svg);
	}
	
	
	.sbox-body {
		position: relative;
		height: 0;
		overflow: hidden;
		min-height: 0;
		color: #f6f6f6;
		padding: 0px 14px;
		@include transition(all, 0.15s, ease-out, 0.0s);
		@include mq-up(sm) { padding: 0 30px; }
		@include mq-up(md) { padding: 0 60px; }
	}
	.sbox-body.visible {
		height: auto;
		min-height: 200px;
		color: $color-black;
		padding-bottom: 14px;
		@include mq-up(sm) { padding-bottom: 30px; margin-top: 10px; }
		@include mq-up(md) { padding-bottom: 60px; margin-top: 20px; }
	}
	
	
	/*=== sbox-body内の要素 ===*/
	
	.row {
		@include mq-up(md) {
			overflow: hidden;
			position: relative;
			padding-bottom: 60px;
		}
	}
	
	.sbox-tn {
		margin: 0 auto 10px;
		
		@include mq-up(sm) { width: 75%; margin-bottom: 20px; }
		
		@include mq-up(md) {
			width: 49%;
			//float: right;
			position: absolute;
			right: 0; bottom: 60px;
			margin-bottom: 0;
		}//-md
		
	}//.sbox-tn
	.sbox-tn > img {
		display: block;
		width: 100%;
		height: auto;
	}
	
	.sbox-data {
		margin-bottom: 14px;
		
		@include mq-up(sm) { margin-bottom: 30px; }
		
		@include mq-up(md) {
			width: 50%;
			margin-bottom: 0;
		}//-md
		
	}//.sbox-data
	
	.sbox-data-table {
		
		font-size: getEm(14px);
		border-collapse: collapse;
		margin-bottom: 1.0em;
		@include mq-up(sm) { margin-bottom: 30px; }
		
		tr { vertical-align: top; }
		th,td { padding-top: 7px; padding-bottom: 7px; }
		th {
			width: 6em;
			font-weight: normal;
		}
		th>.graybase {
			display: block;
			width: 100%;
			text-align: center;
			background-color: #c4c4c4;
		}
		td { padding-left: 7px; }
		
	}//.sbox-data-table
	
	.sbox-note-list {
		font-size: getEm(14px);
		padding: 0 0 0 1.0em;
		text-indent: -1.0em;
		list-style: none;
		
		li + li { margin-top: 0.75em; }
	}
	
	.sbox-plan {}
	
	.sbox-plan-table {
		
		$ek-spt-border: 1px solid #c4c4c4;
		border-collapse: collapse;
		border: $ek-spt-border;
		width: 100%;
		max-width: 620px;
		margin: 0 auto;
		
		tr + tr th,
		tr + tr td { border-top: $ek-spt-border; }
		th + td, td + th { border-left: $ek-spt-border; }
		
		td, th { text-align: center; padding: 0.75em 0; }
		th { background-color: #dedede; font-weight: normal; }
		
	}//.sbox-plan-table
	
}//.services-sect