/*==================== 栄晃 (Nov.2017)
	
	Selling 販売商品
	
	====================*/

.page-selling {
	
	
	
	/*=== .intro-sect タイトル部 ===*/
	
	
	.intro-sect {
		background-image: url(../img/selling/selling-title-bg.jpg);
	}
	
	
	
	/*===== 商品一覧 =====*/
	
	
	
	.products-sect {
		
		.sect-header { margin-bottom: 40px; }
		
		.sect-note { text-align: center; }
		
	}//.products-sect
	
	
	
	/* 単品のコンテナ */
	.prod-box {
		
		background-color: #e9e9e9;
		padding: 10px;
		margin: 0 auto 40px;
		max-width: 880px;
		
		@include mq-up(sm) { padding: 25px; }
		@include mq-up(md) { overflow: hidden; }
		
	}//.prod-box
	
	
	/* サムネイル */
	.prod-tn {
		
		margin-bottom: 20px;
		
		@include mq-up(md) {
			margin-bottom: 0;
			float: left;
			width: 340px;
			height: 255px;
		}
		
		&>img {
			display: block;
			width: 100%;
			height: auto;
		}
		
	}//.prod-tn (サムネイル)
	
	
	/* テキストのコンテナ */
	.prod-body {
		
		@include mq-up(md) { margin-left: (340px + 30px); }
		
		p, ul { margin-bottom: 0.50em; }
		
		ul.list {
			padding-left: 1.25em;
			
			//font-size: getEm(15px);
			//@include mq-up(sm) { font-size: 1.0em; }
			
			// & > li { 
			// 	padding-left: 4.0em;
			// 	text-indent: -4.0em;
			// }
		}//ul.list
		
		ul.note-list {
			list-style: none;
			padding-left: 0;
			
			font-size: getEm(15px);
			//@include mq-up(sm) { font-size: 1.0em; }
			
			&>li {  
				padding-left: 1.0em;
				text-indent: -1.0em;
			}
		}//ul.note-list
		
		.pricing {
			border: 1px solid black;
			border-left: none;  border-right: none;
			margin-top: 1.0em;
			padding: 0.5em 0;
		}
		.saleprice { font-weight: bold; color: $color-orange-dark; }
		
	}//.prod-body
	
	.prod-title {
		font-size: getEm(20px);
		color: $color-blue;
		margin-bottom: 0.75em;
	}
	
}//.page-selling



/*===== シモンズ ( reform でも利用 ) =====*/

.simmons-sect {
	
	margin-bottom: 60px;
	
	.simmons-container {
		background-color: #ffffb8;
		max-width: 880px;
		margin: 0 auto;
		padding-top: 25px;
		text-align: center;
	}
	
	.sect-title {
		font-size: getEm(22px);
		border-bottom: none;
		margin-bottom: 0.25em;
		max-width: none;
		padding: 0 15px;
		@include mq-up(sm) {
			font-size: getEm(24px);
		}
	}
	
	.sect-lead {
		margin-bottom: 0.75em;
		padding: 0 15px;
	}
	
	.sect-tn>img {
		display: block;
		width: 100%;
		height: auto;
	}
	
	.simmons-logo {
		width: 100%;
		height: 120px;
		background: url(../img/selling/simmons-logo.png) center center no-repeat;
		@include swaptext;
	}
}//.simmons-sect
