/*==================== 栄晃
	
	.pageside-nav
	
	.gotop-nav ( トップに戻るボタン)
	
	====================*/



.pageside-nav {
	
	display: none;
	position: fixed;
	right: 0;
	top: 105px;
	width: 50px;
	
	@include mq-up(md) { 
		display: block;
		z-index: 1000;
	}//-md
	
	.psnav-list {
		padding: 0;
		list-style: none;
	}
	
	.psnav-item {
		margin-bottom: 10px;
		height: 140px;// <== 実測値: 指定しないとEdgeで表示崩れが起きたため.
		
		& > a {
			/* .btn-orange スタイルとの組み合わせ */
			display: block;
			padding: 20px 0;
			line-height: 50px;
			-webkit-writing-mode: vertical-rl;
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
			white-space: nowrap;
			
			&:hover { text-decoration: none; }
		}//& > a

		
	}//.psnav-item
	
	
	/* fbボタン */
	.psnav-item:first-child {
		height: 46px;
		
		& > a {
			height: 100%;
			background: #3c4e8a url(../img/common/fb-icon-white.svg) center center no-repeat;
			@include swaptext;
			text-indent: 100px;
		}
		@include mq-up(md) {
			& > a:hover {
				background-color: darken(#3c4e8a, 20%);
				text-decoration: none;
			}
		}
	}//first-child
	
}//.pageside_nav




/*=== トップへ戻るボタン ===*/

.gotop-nav {
	
	position: fixed;
	right: 5px;
	bottom: -50px;
	z-index: 100;// <==???
	width: 50px;
	height: 50px;
	@include transition-solo(bottom);
	
	@include mq-up(md) {
		width: 60px;  height: 60px;
		right: 10px;
	}
	
	& > a {
		display: block;
		width: 100%;
		height: 100%;
		background: url(../img/common/gotop-icon-black.svg) center center / 60% auto no-repeat;
		opacity: 0.5;
		@include transition-solo(opacity);
		@include swaptext;
	}//& > a
	
	&.active { bottom: 5px; }
	
	@include mq-up(md) {
		& > a:hover { opacity: 1.0; }
	}
	
}//.gotop-nav
