/*==================== 栄晃 (Nov.2017)
	
	注文フォームの確認ページ (共通)
	
	
	
	====================*/

.page-confirm {
	
	.intro-body .body-txt { text-align: center; }
	
	.formset .form-table td { font-size: 1.15em; }
	
}//.page-confirm