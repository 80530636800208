/*==================== 栄晃
	
	.page-sub サブページ共通設定
	
	====================*/

.page-sub {
	
	.intro-sect {
		
		width: 100%;
		background-size: cover;
		background-position: center center;
		color: white;
		text-align: center;
		height: 125px;
		margin-bottom: 40px;
		
		.sect-container {
			position: relative;
			height: 100%;
		}
		
		.titles-container {
	    position: relative;
	    top: 50%;
	    -webkit-transform: translateY(-50%);
	    -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	    -o-transform: translateY(-50%);
	    transform: translateY(-50%);
		}
		
		.page-title-en,
		.page-title {
			font-weight: bold;
			line-height: 1.25;
			-webkit-font-smoothing: antialiased;
		}
		
		.page-title-en {
			font-size: getEm(14px);
			font-family: 'Century Gothic', CenturyGothic, 'Avenir', $font-family;
		}
		
		.page-title { font-size: getEm(24px); }
		
		
		@include mq-up(md) {
			height: 250px;
			margin-bottom: 95px;
			.page-title-en { font-size: getEm(25px); }
			.page-title { font-size: getEm(37px); }
		}//-md+
		
	}//.page-title-sect

}//.page-sub