/*==================== 栄晃 (Nov.2017)

	Privacy プライバシーポリシー

	====================*/

.page-privacy {

	/*=== .intro-sect (タイトル部) ===*/

	.intro-sect {
		background-image: url(../img/privacy/privacy-title-bg.jpg);
	}

	.policy-sect {

		margin-bottom: 60px;

		.sect-body {
			max-width: 850px;
			margin: 0 auto;
			line-height: calc(28 / 16);
			text-align: justify;
			font-size: getEm(15px);
			@include mq-up(sm) { font-size: 1.0em; }
		}
		h3 {
			font-size: 1.0em;
			margin: 1.5em 0 0.5em;
			text-align: center;
		}
		p {
			margin: 0.5em 0;
		}

		address > ul {
			font-style: normal;
			list-style: none;
			padding-left: 0;
		}

	}//.policy-sect

}//.page-privacy
