.page-washing {



  //=== ページタイトル ===//
  .intro-sect {
		background-image: url(../img/washing/washing-title-bg-50.jpg);
	}//.intro-sect



  //=== 下層sectionでの共通設定 ===//
  .features__sect, .price__sect {
    .sect-container {
      margin: 0 auto;
      max-width: 910px;// = 880 + 15*2 (左右padding)
    }//.sect-container
  }//.features__sect, .price__sect



  //=== section:特長 ===//
  .features__sect {
    //880*360


    h2 {
      background: url(../img/washing/washing-image2.png) 0 0 / contain no-repeat;
      height: 0;
      padding-top: calc(425 / 880 * 100%);// 425 = 360 + 65 (margin-bottomb分も合わせて設定)
      width: 100%;
      @include swaptext;

      @include mq-up(md) { background-image: url(../img/washing/washing-image2-20@2x.jpg); }
    }//h2

    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      //NOTE: ssm以下で文字サイズが厳しいので, ssmでは2・1の2行表示, sm以上では3の1行表示にします！
      flex-wrap: wrap;
      gap: 0 10px;
      justify-content: center;

      @include mq-up(sm) {
        flex-wrap: nowrap;
        gap: 0;
        justify-content: space-between;
      }

      li {
        align-items: center;
        background-color: #ffffb5;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        flex-grow: 0;
        justify-content: center;
        text-align: center;

        flex-basis: 140px;
        height: 140px;
        width: 140px;//-xs

        @include mq-up(ssm) {
          flex-basis: 160px;
          height: 160px;
          width: 160px;//-sm
        }
        @include mq-up(sm) {
          flex-basis: 174px;
          height: 174px;
          width: 174px;//-sm
        }
        @include mq-up(md) {
          flex-basis: 236px;
          height: 236px;
          width: 236px;//-md
        }
        @include mq-up(lg) {
          flex-basis: 282px;
          height: 282px;
          width: 282px;//-lg+
        }

        h3 {
          color: #f26700;
          font-size: 15px;
          font-family: $font-family-mrou;
          font-weight: 700;
          line-height: calc(35 / 25);
          margin-bottom: getEm(6px, 25px);
          @include mq-up(ssm) { font-size: 16px; }
          @include mq-up(sm) { font-size: 17px; }
          @include mq-up(md) { font-size: 21px; }
          @include mq-up(lg) { font-size: 25px; }
        }//h3

        p {
          font-size: 10px;
          font-family: $font-family-mrou;
          font-weight: 500;
          line-height: calc(31 / 18);
          @include mq-up(ssm) { font-size: 11px; }
          @include mq-up(sm) { font-size: 12px; }
          @include mq-up(md) { font-size: 15px; }
          @include mq-up(lg) { font-size: 18px; }
        }//p

      }//li

    }//ul

  }//.features__sect



  //=== section:料金表 ===//
  .price__sect {

    h2 {
      font-family: $font-family-mrou;
      font-size: 35px * 0.635;
      font-weight: bold;
      line-height: calc(61 / 35);
      margin: getEm(40px, 35px) auto getEm(32px, 35px);
      text-align: center;

      @include mq-up(ssm) { font-size: 35px * 0.75; }
      @include mq-up(sm) { font-size: 35px; }
    }//h2

  }//.price__sect



  //=== 料金表ブロック ===//
  .price__table {
    background-color: #ffffb5;
    font-family: $font-family-mrou;
    font-size: 10px;// `料金表ノート`の文字サイズを本要素の基礎サイズとする
    font-weight: 500;
    margin: 0 auto;
    padding: getEm(24px, 18px) getEm(50px, 18px) getEm(36px, 18px);
    width: 100%;// sm+では固定幅に

    @include mq-up(ssm) {
      font-size: 12px;
    }
    @include mq-up(sm) {
      font-size: 18px;
      width: 546px;
    }
    @include mq-up(md) {
      width: 650px;
    }

    // 料金表タイトル
    .price__table-title {
      font-size: getEm(35px, 18px);
      font-weight: 500;
      text-align: center;
      margin-bottom: 0.25em;
    }//.price__table-title 料金表タイトル

    // 料金表本体
    table {
      border-collapse: collapse;
      font-size: getEm(24px, 18px);
      width: 100%;

      td, th {
        height: getEm(83px, 24px);
        border-bottom: 1px dashed #000;
      }//td, th

      th {
        font-weight: 500;
        text-align: left;
      }

      td {
        text-align: right;
      }
    }//table 料金表本体

    // 料金表ノート
    ul {
      list-style: none;
      margin: 2em 0 0;
      padding: 0;

      li {
        line-height: calc(31 / 18);
      }

    }//ul 料金表ノート

  }//.price__table 料金表ブロック



}//.page-washing
