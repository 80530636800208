/*========== .links-sect @ page-index ==========*/



.links-sect {
	
	margin-bottom: 50px;
	@include mq-up(md) { margin-bottom: 70px; }
	
	.links-container { text-align: center; }
	
	.link-item {
		display: block;
		width: 200px;
		height: 70px;
		margin: 15px auto;
		background: white center center no-repeat;
		border: 2px solid $color-blue-lite;
		@include swaptext;
		&:hover { border-color: darken($color-blue, 20%); }
		
		@include mq-up(sm) {
			display: inline-block;
			margin: 5px 6px;
		}
		
	}
	
	.link-item.lifep {
		background-image: url(../img/index/idx-links-lifep@2x.png);
		background-size: 168px auto;
	}
	.link-item.karhd {
		background-image: url(../img/index/idx-links-karhd@2x.png);
		background-size: 139px auto;
	}
	.link-item.wasdk {
		background-image: url(../img/index/idx-links-wasdk@2x.png);
		background-size: 181px auto;
	}
	.link-item.washm {
		background-image: url(../img/index/idx-links-washm@2x.png);
		background-size: 141px auto;
	}

}//.links-sect