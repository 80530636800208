/*==================== 栄晃 (Nov.2017)

	order ディレクトリ配下

	注文フォーム

	====================*/

.page-order {

	/* タイトル部 */
	.intro-sect {
		background-image: url(../img/order/order-title-bg.jpg);
	}


	/*========== index.html ==========*/


	.form-intro {

		.intro-body {

			max-width: 880px;
			margin: 0 auto;
			line-height: calc(28 / 16);

			.body-txt { margin-bottom: 0.5em; }

			.body-note-list {
				border: 1px solid #909090;
				border-left: none;  border-right: none;
				padding: 1.0em 0;
				margin: 1.0em 0;
				list-style: none;
				font-size: getEm(15px);
				@include mq-up(sm) { font-size: 1.0em; }

				.note-item {
					padding-left: 1.0em; text-indent: -1.0em; margin-bottom: 0.25em;
				}//.note-item

			}//.body-note-list

		}//.intro-body

	}//.form-intro



	/*========== order-sent.html ==========*/

	.sent-sect {
		display: none;
		text-align: center;
		margin: 25px auto;

		&.visible { display: block; }

		p { margin-bottom: 0.5em; }
	}//.sent-sect

	.btn-container {
		margin: 25px auto;
		padding: 0 15px;
	}

}//.page-order

/*=====
	Order Unable (2019.07.12)
=====*/

.orderunable__base {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba(255,255,255,0.85);
	z-index: 4;
}
.orderunable__card {
	font-size: 15px;
	line-height: 1.65;
	width: 290px;
	padding: 2em 0.5em 1em 1em;
	text-align: center;
	background-color: #fff;
	box-shadow: 0px 2px 10px rgba(0,0,0,0.5);
	@include border-radius(0.5em);
}
.orderunable__msg + .orderunable__msg {
	margin-top: 0.25em;
}
.orderunable__toplink {
	margin-top: 1.5em;
	border-top: 1px solid #ddd;
	padding-top: 1em;
}
