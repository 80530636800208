/*==================== 栄晃 (Nov.2017)

	Reform 販売商品

	====================*/

.page-reform {

	/*=== .intro-sect (タイトル部) ===*/

	.intro-sect {
		background-image: url(../img/reform/reform-title-bg.jpg);
	}



	/*=== .reform-sect ===*/

	.reform-sect {

		margin-bottom: 70px;

		.sect-header,
		.sect-body,
		.sect-showcase { max-width: 880px; margin-left: auto; margin-right: auto; }

		/* タイトル */
		.sect-header {
			margin-bottom: 25px;
			@include mq-up(sm) { margin-bottom: 50px; }
		}//.sect-header


		/* 本文 */
		.sect-body {
			overflow: hidden;
			max-width: (880px - 70px);
			margin: 0 auto 70px;
		}//.sect-body

		.reform-tn {
			width: 240px;
			margin: 0 auto 30px;
			//height: auto;
			@include mq-up(sm) { width: (7 * 35px); height: (8 * 35px); margin: 0; float: left; }
			@include mq-up(md) { width: (7 * 50px); height: (8 * 50px); }

			&>img {
				display: block;
				width: 100%;
				height: auto;
			}
		}//.reform-tn

		.reform-body {
			//padding-top: 45px;
			//margin-left: (350px + 70px);
			@include mq-up(sm) { margin-left: (7 * 35px + 30px); }
			@include mq-up(md) { margin-left: (7 * 50px + 70px); padding-top: 45px; }

			.body-list {
				font-size: getEm(18px);
				padding-left: 1.4em;
				text-align: center;
				list-style-position: inside;
				@include mq-up(sm) { font-size: getEm(21px); text-align: left; list-style-position: outside; }
				@include mq-up(md) { font-size: getEm(24px); }
				> li { margin-bottom: calc(20 / 24 * 1.0em); }
			}//.body-list

			.body-copy {
				font-size: getEm(17px);
				line-height: calc(35 / 20);
				margin-bottom: 1.0em;
				text-align: center;
				@include mq-up(sm) { font-size: getEm(18px); text-align: left; }
				@include mq-up(md) { font-size: getEm(20px); }
			}

			.body-rep {
				font-size: getEm(15px);
				text-align: center;
				@include mq-up(sm) { font-size: getEm(17px); text-align: left; }
				@include mq-up(md) { font-size: getEm(18px); }
			}

		}//.reform-body


		/* ショウケース */
		.sect-showcase {

			overflow: hidden;

			.case {
				width: 100%;
				@include mq-up(sm) {
					float: left;
					width: calc(280 / 880 * 100%);
				}

				> img {
					display: block;
					width: 100%;
					height: auto;
				}
			}//.case

			.case + .case {
				margin-top: 15px;
				@include mq-up(sm) {
					margin-left: calc(20 / 880 * 100%);
					margin-top: 0;
				}
			}//.case + .case

		}//.sect-showcase



	}//.reform-sect




}//.page-reform
