/*==================== 栄晃
	
	company.html
	
	会社概要ページ
	
	====================*/



.page-company {
	
	
	
	/*=== .intro-sect タイトル部 ===*/
	.intro-sect {
		background-image: url(../img/company/company-title-bg.jpg);
	}
	
	
	
	/*=== .info-sect ===*/
	
	.info-sect {
		margin-bottom: 70px;
	}
	
	.info-table {
		
		$it-border-style: 2px solid $color-blue-lite;
		
		border: $it-border-style;
		background-color: white;
		border-collapse: collapse;
		width: 100%;
		max-width: 880px;
		margin: 0 auto;
		
		tr + tr td,
		tr + tr th { border-top: $it-border-style; }
		
		td,th {
			vertical-align: top;
			padding: 10px;
			@include mq-up(sm) { padding: 20px; }
		}
		
		th {
			text-align: left;
			@include mq-up(sm) { width: 30%; }
		}
		ul { padding-left: 1.0em; }
		
	}//.info-table
	
	
	
	/*=== .map-sect ===*/
	
	.map-sect {
		
		.map-container {
			width: 100%;
			height: 300px;
			@include mq-up(sm) {
				height: 400px;
			}
			@include mq-up(md) {
				height: 500px;
			}
		}
		
		iframe {
			width: 100%;
			height: 100%;
		}
		
	}//.map-sect
	
	
	
}//.page-company