/*========== .news-sect @ page-index ==========*/



.news-sect {
	
	margin-bottom: 50px;
	@include mq-up(sm) { margin-bottom: 80px; }
	
	.news-list {
		margin: 0 auto;
		padding: 0;
		list-style: none;
		max-width: 560px;
		@include mq-up(md) { margin-bottom: 30px; }
	}
	
	.news-item {
		margin-bottom: 1.0em;
		text-align: center;
	}
	
	.news-item a {
		margin-left: 0.5em;
	}
	
	.btn-orange {
		@include mq-up(md) { width: 300px; }
	}
	
}//.news-sect {}
